<template>
  <div>
    <a-card size="small" :bordered="false" style="height: 100%" :bodyStyle="{ height: '200px' }">
      <template slot="title">{{ $t("实时出入库量信息概览") }}</template>
      <template slot="extra">
        <a-range-picker
          v-model="dateRange"
          size="small"
          :allowClear="false"
          style="width: 200px; margin: -4px 0"
          @change="queryData"
        />
      </template>

      <div id="RealTimeInventoryChart" style="height: 100%"></div>
    </a-card>
  </div>
</template>

<script>
import { storeInfoList } from "@/api/statistic";
import { Line } from "@antv/g2plot";
import moment from "moment";

export default {
  data() {
    return {
      dataChart: null,
      dataItems: [],

      dateRange: [moment().startOf("day"), moment().add(1, "days").startOf("day")],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    queryData() {
      const startTime = this.dateRange[0].format("YYYY-MM-DD HH:mm:ss");
      const endTime = this.dateRange[1].format("YYYY-MM-DD HH:mm:ss");
      const diffDays = this.dateRange[1].diff(this.dateRange[0], "hours");

      storeInfoList({ warehouse: this.currentWarehouse, start_time: startTime, end_time: endTime }).then((data) => {
        const dataItems = [];
        for (let index = 0; index < diffDays; index++) {
          const date = this.dateRange[0].clone().add(index, "hours").format("YYYY-MM-DD HH:mm");
          const stockInItem = { date, quantity: 0, type: this.$t("入库") };
          const stockOutItem = { date, quantity: 0, type: this.$t("出库") };
          dataItems.push(stockInItem);
          dataItems.push(stockOutItem);

          for (let item of data.results) {
            item.date = moment(item.date).format("YYYY-MM-DD HH:mm");
            if (item.date === date && item.type === this.$t("入库")) {
              stockInItem.quantity = item.quantity;
            }

            if (item.date === date && item.type === this.$t("出库")) {
              stockOutItem.quantity = item.quantity;
            }
          }
        }

        this.dataItems = dataItems;
        this.dataChart.changeData(this.dataItems);
      });
    },
  },
  mounted() {
    this.dataChart = new Line("RealTimeInventoryChart", {
      data: [],
      autoFit: true,
      xField: "date",
      yField: "quantity",
      seriesField: "type",
      label: {},
      meta: { date: { alias: this.$t("时间") }, quantity: { alias: this.$t("数量") } },
    });
    this.dataChart.render();

    this.queryData();
  },
};
</script>
